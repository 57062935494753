import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/dtmoney.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/ignews.jpeg'
import IMG4 from '../../assets/esports.jpeg'
import IMG5 from '../../assets/natrave1.png'
import IMG6 from '../../assets/designsystem.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Dtmoney - Finance Dashboard',
    github: 'https://github.com/lucasmrau/dtmoney',
    demo: 'https://dt-money-two.vercel.app/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Portfolio Website',
    github: 'https://github.com/lucasmrau/portfolio',
    demo: 'http://www.lucasrau.com/'
  },
  {
    id: 2,
    image: IMG3,
    title: 'Ignews - Blog Newsletter',
    github: 'https://github.com/lucasmrau/ignews',
     demo: 'https://ig-news-theta.vercel.app/'
  },
  {
    id: 2,
    image: IMG4,
    title: 'Find your duo - Esports Group Finder',
    github: 'https://github.com/lucasmrau/find-your-duo',
    demo: 'https://nlw-esports-ignite.vercel.app/'
  },
  {
    id: 2,
    image: IMG5,
    title: 'Na trave - predictions for the World Cup matches!',
    github: 'https://github.com/lucasmrau/na-trave',
    demo: 'https://natrave.vercel.app//'
  },
  {
    id: 2,
    image: IMG6,
    title: 'Design System - Figma implemented with Storybook',
    github: 'https://github.com/lucasmrau/design-system',
    demo: 'https://rocketseat-education.github.io/ignite-lab-design-system/?path=/story/components-checkbox--default'
  },
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank' rel="noreferrer" >Github</a>
                {<a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>}
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio