import React from 'react'
import './about.css'
import ME from '../../assets/me2.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About me"/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Computer Programming</h5>
              <small>Georgian College</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Professional Scrum Master I</h5>
              <small>Scrum.org - </small>
              <small>certificate n. 804475</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Master in Law and Economics</h5>
              <small>Pontificia Universidade Católica - Brazil</small>
            </article>
          </div>

          <p>
          3+ years of experience as a Software Engineer, developing Full Stack skills mainly with JavaScript, Node, frameworks, and libraries. I completed 2 internships in addition to working as an IT volunteer. Educational basis through College, University and Programming Bootcamp. 
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About